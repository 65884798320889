<template>
  <router-view />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Token from "@/api/Token";

export default {
  computed: {
    ...mapGetters(["isLoggedIn"])
  },
  methods: {
    ...mapActions(["getMe"]),
    redentToInicioPage() {
      this.$router.push("/");
    }
  },
  async created() {
    if (this.isLoggedIn) {
      this.redentToInicioPage();
    } else {
      const auth = JSON.parse(sessionStorage.getItem("auth")) || {};
      this.token = auth.token;
      if (this.token) {
        Token.set(this.token);
        const { data } = await this.getMe();
        if (data) this.redentToInicioPage();
      }
    }
  }
};
</script>

<style></style>
